//export const internalUrl = 'https://api-nonprod.pgsvc.com/oralb-pokemon'
export const homepageurl = '/api/v1/pages/get-home-page?locale=ja-jp&language='
export const settingsurl = '/api/v1/pages/settings?locale=ja-jp&language='
export const registrationURL = '/api/v1/grs/get-registration-form?locale=ja-jp&language='
export const navigayionurl = '/api/v1/pages/get-navigation?locale=ja-jp&language='
export const loginformurl = '/api/v1/grs/get-login-form?locale=ja-jp&language='
export const loginAPI = '/api/v1/Janrain/login?locale=ja-jp&language='
export const registerAPI = '/api/v1/Janrain/registration?locale=ja-jp&language='
export const brushPagAPI ='/api/v1/pages/get-brusher-page?locale=ja-jp&language='
export const postbrushPageAPI = '/api/v1/pages/add-profile?locale=ja-jp&language='
export const startbrushPageAPI = '/api/v1/pages/start-brush-page?locale=ja-jp&language='
export const imagerevelAPI = '/api/v1/pages/get-image-reveal?locale=ja-jp&language='
export const unlockpokemonurl = '/api/v1/pages/unlock-page?locale=ja-jp&language='
export const getCharactersListAPI = '/api/v1/pages/get-profileImages?locale=ja-jp&language=';
export const postKidProfileImageAPI = '/api/v1/pages/edit-profileImage?locale=ja-jp&language=';
export const postGetUserDetailAPI = '/api/v1/Janrain/edit-registration?locale=ja-jp&language=';
export const postEditProfileAPI = '/api/v1/Janrain/update-profile?locale=ja-jp&language=';
export const postForgotPasswordAPI ='/api/v1/Janrain/forgot-password?locale=ja-jp&language=';
export const postResetPasswordAPI ='/api/v1/Janrain/change-password?locale=ja-jp&language=';
export const editProfile = '/api/v1/pages/edit-profile?locale=ja-jp&language=';
export const deletProfile ='/api/v1/pages/delete-profile?locale=ja-jp&language=';
export const calendarAPI = '/api/v1/pages/get-brusher-calendar?locale=ja-jp&language='
export const getTokenAPI = '/api/v1/auth/gettoken'
export const defaultLang = 'ja'
export const internalLang = 'ja'
export const defaultCountry = 'JP'
export const loginMaxTry = 10;
export const accountLockedDuration =  3600000; // 1hr
export const privacyAgeLimit = 18;

import {Constants, PokemonImages} from '../../../adapters/helpers/Constants'
export const theme = (themeName)=>{
    let themeObj = {
        ThemeNewYear : PokemonImages.newYear,
        ThemeNewLifeDesign :PokemonImages.LifeDesign,
        ThemeGreen : PokemonImages.Green,
        ThemeSummer : PokemonImages.kidsBackground,
        ThemeRenewalXmas : PokemonImages.Renewalxmas
    }
    return themeObj[themeName] ? themeObj[themeName] : themeObj['ThemeNewYear']
}
export const loginFieldObj = (lang) => {
    let formobject = {
        ja: {
            'accountLocked': 'あなたのアカウントは 1 時間ロックされます。',
            'email': 'メールアドレス*',
            'passWord': 'パスワード*',
            'button': ' ログイン ',
            'loginFailedMsg' : 'メールアドレス、又はパスワードが間違っています。ご確認の上もう一度入力して頂くか、下記のリンクをクリックして、新しいアカウントを作成し'
        },
        en: {
            'accountLocked': 'Your account is locked for one hour!',
            'email': 'Email*',
            'passWord': Constants?.engPassword + '*',
            'button': 'SIGN IN',
            'loginFailedMsg' : 'The email and password combination you entered is incorrect. Please try again, or click the link below to create an account.'
        }
    }
    return formobject[lang]
}

export const agePrivacyTextObject = ( lang ) => {
    let formobject = {
        ja: {
            'policyText': <>申し訳ありませんが、P&amp;Gグループでは18歳以下のお客様の会員登録及び個人情報を受け取ることができません。当サイト内の会員登録なしにご覧いただけるコンテンツをお楽しみください！ また、P&amp;Gとそのブランドについては</>,
            'ariaLabel': 'でご覧ください。',
            'url': 'https://jp.pg.com',
            'policyTextAfter': 'でご覧ください。',
            'homeText': 'ホームページへ戻る',
            'homeLink': '/ja-jp/brushingmaster',
        },
        en: {
            'policyText': <>We're sorry, but we can't accept your messages or personal information because you do not meet our eligibility requirements. But that doesn't mean you can't explore the rest of our site and learn more about us! Check out</>,
            'ariaLabel': 'Please take a look at',
            'url': 'https://jp.pg.com',
            'policyTextAfter': 'for information about P&G and its brands.',
            'homeText': 'Return to homepage',
            'homeLink': '/ja-jp/brushingmaster',
        }
    }
    return formobject[lang]
}

export const editProfileObj = (lang) => {
    let formobject = {
        ja: {
            'editProfileTitle' : 'プロフィールを更新する（保護者の方の情報をご記入ください',
            'editProfileButtonText': ' 更新 '
        },
        en: {
            'editProfileTitle' : "Update your parent’s/Guardian’s info",
            'editProfileButtonText': 'Update Your Profile '
        }
    }
    return formobject[lang]
};

export const forgotPasswordObj = (lang) => {
    let formobject = {
        ja: {
            'forgotPasswordTitle' : 'パスワードを忘れた場合',
            'email': 'メールアドレス*',
            'button': ' 送信 ',
            'emailFieldCheck': ' 必要な情報をご入力ください。 ',
            'emailFieldPatternCheck': '有効なEメール アドレスを入力してください。 ',
            'emailSentSuccess': ' パスワード再設定のメールを、登録されたメールアドレスに送信しました。',
            'emailSentFail': ' アカウント登録時に使用したメールアドレスを入力してください。'
        },
        en: {
            'forgotPasswordTitle' : 'Forgot Password',
            'email': 'Email*',
            'button': 'Submit ',
            'emailFieldCheck': 'This is a mandatory field.',
            'emailFieldPatternCheck': 'Please enter a valid email address. ',
            'emailSentSuccess': 'We have sent a reset password mail to your registered e-mail address.',
            'emailSentFail': 'The email you entered is incorrect.'
        }
    }
    return formobject[lang]
};

export const resetPasswordObj = (lang) => {
    let formobject = {
        ja: {
            'resetPasswordTitle' : 'パスワードの再設定',
            'password': 'パスワード*',
            'confirmPassword': 'パスワード（再入力*',
            'button': '送信 ',
            'blankValidationPwdMsg' : '必要な情報をご入力ください。',
            'lengthValidationPwdMsg' : 'パスワードは8～14文字で、大文字、小文字、数字をそれぞれ1文字以上含む必要があります。',
            'cnfPwdValidationMsg' : '[パスワード]と[パスワード(再入力)]が一致しません',
            'pwdUpdateSuccess' : 'パスワードの再設定が完了しました。',
            'pwdUpdateFail' : 'authorization_code is not valid'

        },
        en: {
            'resetPasswordTitle' : 'Reset Password',
            'password': Constants?.engPassword + '*',
            'confirmPassword': 'Confirm Password*',
            'button': 'Create Your New Password ',
            'blankValidationPwdMsg' : 'This is a mandatory field.',
            'lengthValidationPwdMsg' : 'The password must be 8-14 characters long and contain at least one uppercase letter, one lowercase letter, and one number.',
            'cnfPwdValidationMsg' : 'Password and Confirm Password values must match. Please try again.',
            'pwdUpdateSuccess' : 'New password set successfully.',
            'pwdUpdateFail' : 'authorization_code is not valid'
        }
    }
    return formobject[lang]
};

export const linkExpireObj = (lang) => {
    let formobject = {
        ja: {
            'oopsText' : 'おっとっと！',
            'linkMsg'  : 'リンクの有効期限が切れているようです',
            'btnText'  : '家'

        },
        en: {
            'oopsText' : 'Oops!',
            'linkMsg'  : 'Looks like the link has expired.',
            'btnText'  : 'Home'
        }
    }
    return formobject[lang]
};

export const otherSettingsObj = (lang) => {
    let formobject = {
        ja: {
            'titleText' : 'その他の設定',
            'btnTextHome'  : '家'
        },
        en: {
            'titleText' : "Other Settings",
            'btnTextHome'  : 'Home'
        }
    }
    return formobject[lang]
};

export const inputFieldValidatiMsgObj = (lang) => {
    let formobject = {
        ja: {
            'mandatoryCheckMsg': ' 必要な情報をご入力ください。',
            'emailFieldPatternCheck': '有効なEメール アドレスを入力してください。',
            'cnfPwdValidationMsg' : '[パスワード]と[パスワード(再入力)]が一致しません',
            'lengthValidationPwdMsg': 'パスワードは8～14文字で、大文字、小文字、数字をそれぞれ1文字以上含む必要があります。',
            'validLastNameMsg' : '有効な姓を入力してください',
            'validFirstNameMsg' : '有効な名を入力してください'
        },
        en: {
            'mandatoryCheckMsg': 'This is a mandatory field.',
            'emailFieldPatternCheck': 'Please enter a valid email address.',
            'cnfPwdValidationMsg' : 'Password and Confirm Password values must match. Please try again.',
            'lengthValidationPwdMsg': 'The password must be 8-14 characters long and contain at least one uppercase letter, one lowercase letter, and one number.',
            'validLastNameMsg' : 'Please enter valid last name',
            'validFirstNameMsg' : 'Please enter valid first name',
        }
    }
    return formobject[lang]
};


export const  backgroundMusic = 'https://assets.ctfassets.net/3tav24ybs0ka/5DmhsGWqIh1zT2N3sT3kT7/d115729f4217b08ef1a69de34b33b0e2/background-music_new.mp3';
export const  drumMusic = 'https://assets.ctfassets.net/3tav24ybs0ka/1y84B2nBBAW3HqFU3qw94q/f60f54456065860392231c4bc7cc590c/Drum_Roll_Gaming_Sound_Effect.mp3';
export const  brushingMusic = 'https://assets.ctfassets.net/3tav24ybs0ka/3eULXM2p7cIc7rz4RziDbQ/4d4417f8b86c54f7ac505d6884fa10be/brushingtimer-with-drumroll.mp3';
export const  kidsYayMusic = 'https://assets.ctfassets.net/3tav24ybs0ka/1XwximCeSIsRekho45NvqW/73e36391911f50c56309be8ec95d2ec3/Kids_Cheering_Gaming_Sound_Effect.mp3';
export const otherMsgTxt = (lang) => {
    let formobject = {
        ja: {
            'tipMsg': 'チップ',
        },
        en: {
            'tipMsg': 'Tips',
        }
    }
    return formobject[lang]
}

export const starMessageText = (lang) => {
    let formobject = {
        ja: {
            'niceWork': 'よくやった！',
            'timesBrushedOnce': '今日は1回ブラッシングしましたね！',    
            'timesBrushedTwice': '今日は2回ブラッシングしましたね！'
        },
        en: {
            'niceWork': 'Nice Work!',
            'timesBrushedOnce': 'You brushed once today',
            'timesBrushedTwice': 'You brushed Twice today'
        }
    }
    return formobject[lang]
}

export const errorMsg404 = (lang) => {
    let error404Object = {
        ja : `<p>ポケモンブラッシングマスターをご利用いただきましたのに、ご不便をおかけし申し訳ございません。<br/><br/>
            現在、長期メンテナンスを行っております。<br/>
            メンテナンス終了時期は、現時点で決定しておりません。<br/>
            そのため、再開日時が判明次第、ブラッシングマスターにご登録のメールアドレス宛てに、ご連絡させていただく予定です。<br/><br/>
            ご利用いただくみなさまに、よりよいブラッシング体験を提供できるよう努めて参りますので、今後ともオーラルB製品をよろしくお願いいたします。</p>`,
        en : `<p></p>Currently under the long term maintenance<br/><br/>
            The completion date is not identified yet. Therefore, we plan to inform the recovered date, when determined to emails address registered in brushing master.<br><br/>
            We continue to offer the better brushing experience.<br/><br/>
            We are very sorry for inconveniences and appreciate your patience.</p>`
    }
    return error404Object[lang]
}
