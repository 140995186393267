import React,{useState,useEffect} from 'react'
import dynamic from 'next/dynamic'
const SettingsDialog = dynamic(() => import('./Dialog/SettingsDialog'))
import useFetch from './utils/ApiHooks'
import { useRouter } from 'next/router'
import { defaultLang,settingsurl} from './utils/constant'

const Homepage = dynamic(() => import('./HomePage'))

let internalUrl = process.env.POKEMON_API_URL

function LadningPage(){
    const [isSettingsDialog,setIsSettingsDialog] = useState(false)
    const [language,setLanguage] = useState(defaultLang)
    const router = useRouter()
    const [settings] = useFetch(`${internalUrl}${settingsurl}${language?language:defaultLang}`,defaultLang)

    useEffect(()=>{
        let language = localStorage.getItem('language')
        setLanguage(language)
        if(!language){
            setIsSettingsDialog(true)
        }
    },[])

    const updateagain = ()=>{
        setIsSettingsDialog(false)
        setLanguage(localStorage.getItem('language'))
        router.push('/ja-jp/brushingmaster')        
    }
    
    return(
        <div>
            {isSettingsDialog && <SettingsDialog data={settings} callbackfunc={updateagain}/>}
            <Homepage lang={language} settingsdata={settings}/>
        </div>
    )
}

export default LadningPage