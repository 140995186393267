import {useEffect,useState} from 'react'
import {getTokenAPI} from './constant'
function useFetch(url,lang,isAuthenticated = false){
const [data,setData] = useState(null);

useEffect(() => {
    
    const  getTokenAPICall  = async () =>{
        const tempEAPIMAuthKey = localStorage.getItem('TempEAPIMAuthKey')
        const tempEAPIMAuthKeyExp = localStorage.getItem('TempEAPIMAuthKeyExp') || 0
        const curTimeStampSec = Math.floor(Date.now() / 1000)
       
        const headers = {
            "Ocp-Apim-Subscription-Key": process.env.EAPIM_SUBSCRIPTION_KEY
        }

        if(tempEAPIMAuthKeyExp-curTimeStampSec < 300 || !tempEAPIMAuthKey) {
            const tokeAPIRes = await fetch(process.env.POKEMON_API_URL+getTokenAPI,{method : 'GET', mode: 'cors', headers: headers})  
            const tokenData = await tokeAPIRes.json()
            const finalBearerToke = `${tokenData.token_type} ${tokenData.access_token}`
            if(tokenData.access_token){
                localStorage.setItem('TempEAPIMAuthKey', finalBearerToke)
                localStorage.setItem('TempEAPIMAuthKeyExp', tokenData.expires_on)
                callAPIs()
            }
            else{            
                console.log(tokenData)
            }
        }
        else {
            localStorage.getItem('TempEAPIMAuthKey')
            callAPIs()
        }
    }

    getTokenAPICall()
  
}, [lang])

const callAPIs = () => {
    let language = localStorage.getItem('language')
    let headers = {
        "Ocp-Apim-Subscription-Key": process.env.EAPIM_SUBSCRIPTION_KEY,
        "Authorization": localStorage.getItem('TempEAPIMAuthKey')
    }

    if(isAuthenticated){
        let authCode = localStorage.getItem('RefreshTokenResponse')
        let urn = localStorage.getItem('Parentuuid')
        headers.urn = urn
        headers.authCode = authCode
    }
    
    if(language){
        lang=language
        url = url.substr(0,url.length-2)+lang
    }
    
    fetch(url,{
        method : 'GET',
        mode: 'cors',
        headers: headers
    }).then((response) => {
        return response.json()
    }).then((data) => {
        setData(data)
    }).catch((error) => {
        console.log(error)
    })
}

return [data]
}

export default useFetch